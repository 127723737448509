@import "./Mixins";

.Priser {
    width: 100vw;

    position: absolute;
    top: 0;
    bottom: 0;

    &-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 200px 1fr;
        justify-items: center;
        gap: 2rem;

        min-height: 100%;
        width: 100vw;
        z-index: 1;

        position: relative;
        
        &_title {
            grid-row: 1 / 1;
            grid-column: 1 / -1;

            place-self: center;

            font-size: var(--fs-heading-big);
            font-family: var(--font-saira);

            text-decoration: underline;
            text-underline-offset: 15px;
            text-decoration-thickness: 3px;
        }

        &_prices {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: minmax(50px, fit-content);
            place-items: center;
            row-gap: 4rem;
            column-gap: 2rem;

            grid-row: 2 / 2;

            align-self: start;

            @include lg() {
                grid-template-columns: repeat(2, 1fr);
            }

            @include md() {
                grid-template-columns: 300px;
                justify-self: center;
                column-gap: 0;
            }

            &--container {
                border-left: solid .3rem #000;
                padding-left: 2.5rem;

                margin: 0rem 1rem;

                @include lg() {
                    &:last-child {
                        margin-bottom: 5rem;
                    }
                }

                @include md() {

                    &:nth-child(even) {
                        border-left: none;
                        border-right: solid .3rem #000;
                        padding-right: 2.5rem;
                    }
                }

                & h1 {
                    font-size: var(--fs-heading);
                }

                & li {
                    font-size: var(--fs-text-big);

                    @include lg() {
                        font-size: var(--fs-text);
                    }
                }
            }
        }
    }
}