.Kontakt {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;


    &-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 200px .5fr;

        position: relative;
        height: 100%;
        width: 100vw;
        z-index: 1;

        @include md() {
            grid-template-columns: 1fr;
            grid-template-rows: 150px .5fr 1fr;

            padding-bottom: 5rem;

            row-gap: 2rem;
        }

        &_title {
            grid-column: 1 / -1;
            grid-row: 1 / 1;

            place-self: center;

            font-size: var(--fs-heading-big);
            font-family: var(--font-saira);
            text-decoration: underline;
            text-underline-offset: 15px;
            text-decoration-thickness: 3px;
        }

        &_contactItems {
            grid-column: 1 / 1;
            grid-row: 2 / 2;

            place-self: center;

            display: flex;
            flex-direction: column;
            gap: 4rem;


            font-size: var(--fs-heading);

            @include lg() {
                font-size: var(--fs-title);
            }

            @include md() {
                font-size: var(--fs-title);
            }

            @include sm() {
                font-size: var(--fs-text-big);
            }

            &--item {
                display: flex;
                flex-direction: row;
                align-items: center;

                gap: 1rem;
                color: var(--lightBlue);
                font-weight: 300;

                @include md() {
                    justify-content: center;
                }
            }
        }

        &_map {
            display: grid;

            grid-column: 2 / 2;
            grid-row: 2 / 2;

            place-self: center;
            height: 100%;
            width: 100%;

            @include md() {
                grid-row: 3 / 3;
                grid-column: 1 / 1;
            }
        }
    }
}

.iframeMap {
    height: 100%;
    width: 70%;

    place-self: center;

    @include sm() {
        width: 90%;
    }
}