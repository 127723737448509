@import "./Mixins";

.Digitalisering {
    display: grid;
}


.digitalisering-retusj {
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & div {
        padding: 3rem 2rem;
        margin: 0 auto;
        width: 30%;

        @include lg() {
            width: 50%;
        }

        @include sm() {
            width: 80%;
            // width: 0;

            // display: flex;
            // flex-direction: column;
            // width: 30rem;

            // margin: 0rem 4rem;

            // padding: 3rem 2rem;
        }

        & h2 {
            font-size: var(--fs-title);
            font-weight: 500;
            text-align: center;

            margin-bottom: 2.5rem;
        }

        & p {
            text-align: center;
            font-size: var(--fs-text-big);

            @include sm() {
                font-size: var(--fs-text);
            }
        }
    }

    &_left {
        border-bottom: 2px solid #000;
    }

}

.digitalisering-retusj-btn {
    display: block;
    background-color: rgba(0, 0, 0, 0.80);
    margin: 0 auto;
    color: #fff;
    padding: 2rem 6rem;
    font-size: var(--fs-heading);
    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 20px 25px -5px, rgba(0, 0, 0, 0.14) 0px 10px 10px -5px;
    cursor: pointer;
    margin-top: 50px;

    transition: background-color .2s ease;
    text-decoration: none;

    &:hover {
        background-color: rgba(0, 0, 0, 0.90);
    }
}

.digitalisering-retusj-btnLink {
    text-decoration: none;
}

.digitalisering-retusj-eksempler {
    margin-top: 6em;

    & h2 {
        font-size: var(--fs-heading-big);
        font-weight: 300;
        text-align: center;

    }
}

.digitalisering-retusj-eksempler-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 10rem;
    row-gap: 5rem;
    margin-top: 50px;
    padding-bottom: 150px;

    &_digi {
        display: flex;
        flex-direction: column;
        gap: 4rem;

        & div {
            width: 400px;

            & img {
                width: 100%;
                height: 250px;
                object-fit: contain;
            }

            & h3 {
                text-align: center;
                font-size: 20px;
                font-weight: 400;
            }
        }
    }

    &_retusj {
        display: flex;
        flex-direction: column;
        gap: 4rem;

        & div {
            width: 400px;

            & img {
                width: 100%;
                height: 250px;
                object-fit: contain;
            }

            & h3 {
                text-align: center;
                font-size: 20px;
                font-weight: 400;
            }
        }
    }

}

.eksempelTitle {
    text-decoration: underline;
    text-underline-offset: 15px;
    text-decoration-thickness: 3px;
}

.loader {
    grid-column: 1 / -1;
    grid-row: 2 / 2;
}

.Loading-header {
    grid-column: 1 / 1;
    grid-row: 1 / 1;

    font-family: var(--font-saira);
    font-size: var(--fs-heading-big);
    margin-bottom: 8rem;
}

// .digitaliseringTitle {

//     @include sm() {
//         font-size: var(--fs-text) !important;
//     }
// }