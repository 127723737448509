$screen-sm-min: 576px;

$screen-md-min: 768px;

$screen-lg-min: 992px;

$screen-xl-min: 1200px;

$screen-xxl2: 1550px;

$screen-xxl3: 1920px;

$screen-xxl4: 2560px;


$navbar-height: 8rem;



@mixin sm {
    @media(max-width: #{$screen-sm-min}) {
        @content;
    }
}



@mixin md {
    @media(max-width: #{$screen-md-min}) {
        @content;
    }
}


@mixin lg {
    @media(max-width: #{$screen-lg-min}) {
        @content;
    }
}


@mixin xl {
    @media(max-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin xxl2 {
    @media(max-width: #{$screen-xxl2}) {
        @content;
    }
}

@mixin xxl3 {
    @media(max-width: #{$screen-xxl3}) {
        @content;
    }
}

@mixin xxl4 {
    @media(max-width: #{$screen-xxl4}) {
        @content;
    }
}

