@import "./Mixins";

.Home {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--alabaster);
    width: 100vw;


    &-landing {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        justify-items: center;
        gap: 2rem;

        margin-top: -8rem;
        position: relative;
        height: 100%;
        width: 100vw;
        z-index: 1;

        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &_logo {
            z-index: 3;
            grid-column: 1 / 1;
            grid-row: 1 / span 2;
            align-self: center;
            width: 40%;
            height: auto;

            @include lg() {
                width: 60%;
            }

            @include md() {
                width: 70%;
            }

            @include sm() {
                width: 80%;
            }
        }

        &_title {
            z-index: 3;
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            align-self: end;

            font-size: var(--fs-heading-big);
            font-family: var(--font-saira);
            font-weight: 300;

            color: #fff;

            @include sm() {
                font-size: var(--fs-heading);
                text-align: center;
            }
        }

        // &_undertitle {
        //     z-index: 3;
        //     grid-column: 1 / 1;
        //     grid-row: 2 / 2;
        //     align-self: start;

        //     font-size: var(--fs-heading);
        //     font-family: var(--font-saira);
        //     font-weight: 300;

        //     color: #fff;
        // }

        &_arrowUp {
            grid-column: 1 / 1;
            grid-row: 2 / 2;
            place-self: center;

            height: 5rem;
            width: 5rem;

            fill: #fff;

            cursor: pointer;

            z-index: 3;
        }

        &_overlay {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(1px) saturate(120%);
        }
    }

    &-about {
        padding-top: 8rem;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 200px 1fr 400px;

        position: relative;
        min-height: 100%;
        width: 100vw;
        z-index: 1;

        @include md() {
            grid-template-rows: 200px repeat(3, 1fr);
            grid-template-columns: 1fr;
        }

        @include sm() {
            grid-template-rows: 200px repeat(3, 1fr);
            grid-template-columns: 1fr;
            row-gap: 3rem;
        }

        &_title {
            grid-column: 1 / -1;
            grid-row: 1 / 1;

            place-self: center;

            font-size: var(--fs-heading);
            font-family: var(--font-saira);

            text-decoration: underline;
            text-underline-offset: 15px;
            text-decoration-thickness: 3px;
        }

        &_text {
            grid-column: 1 / 1;
            grid-row: 2 / 2;

            place-self: center;
            font-size: var(--fs-text-big);

            width: 70%;

            @include sm() {
                width: 90%;
                font-size: var(--fs-text-big);
            }
        }

        &_img {
            grid-column: 2 / 2;
            grid-row: 2 / 2;

            place-self: center;

            height: 40rem;

            box-shadow: rgba(0, 0, 0, 0.39) 0px 10px 20px, rgba(0, 0, 0, 0.43) 0px 6px 10px;

            @include md() {
                grid-column: 1 / 1;
                grid-row: 3 / 3;
            }
        }

        &_ref {
            grid-column: 1 / span 2;
            grid-row: 3 / 3;

            align-self: start;
            justify-self: center;

            margin-top: 2rem;

            font-size: var(--fs-heading);
            font-family: var(--font-saira);

            text-decoration: underline;
            text-underline-offset: 15px;
            text-decoration-thickness: 3px;

            @include md() {
                grid-column: 1 / 1;
                grid-row: 4 / 4;
            }
        }

        &_carousel {
            grid-column: 1 / span 2;
            grid-row: 3 / 3;

            place-self: center;

            padding: 0rem 30rem;

            @include lg() {
                padding: 0rem 10rem;
            }

            @include md() {
                padding: 0rem 5rem;
                grid-column: 1 / 1;
                grid-row: 4 / 4;
            }

            @include sm() {
                padding: 0rem 2rem;
            }
        }
    }
}

.carouselTitle {
    font-size: 2rem;
    text-align: center;
}

.carouselText {
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    width: 70%;
    margin: 0 auto;
    margin-top: 1rem;
}

.rec.rec-arrow {
    background-color: #000;
    color: #fff;

    &:hover {
        background-color: #000 !important;
    }

    &:focus {
        background-color: #000 !important;
    }

    &:active {
        background-color: #000 !important;
    }
}

.rec.rec-arrow:disabled {
    visibility: hidden;
}

.rec-carousel-item {
    // border-top: solid .2rem #000;
    border-bottom: solid .2rem #000;

    padding: 2rem 0rem;
}

.rec-dot {
    box-shadow: 0 0 1px 3px #000 !important;
}

.rec-dot_active {
    background-color: #000 !important;
}

.jJOCMs {
    width: 100% !important;

    // @include md() {
    //     width: 70% !important;
    // }

    // @include sm() {
    //     width: 95% !important;
    // }
}