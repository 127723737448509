@import "./Mixins";
@import './base';

.GalleryPage {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--alabaster);
    width: 100vw;
    min-height: min-content;

    &-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 200px 1fr 100px;
        gap: 2rem;

        position: relative;
        min-height: 100%;
        width: 100vw;
        z-index: 1;

        padding-bottom: 10rem;

        &_loader {
            grid-row: 2 / 2;
            grid-column: 1 / -1;

            place-self: center;
        }

        &_title {
            grid-row: 1 / 1;
            grid-column: 1 / -1;
            align-self: center;

            text-align: center;
            place-self: center;

            font-size: var(--fs-heading-big);
            font-family: var(--font-saira);

            text-decoration: underline;
            text-underline-offset: 15px;
            text-decoration-thickness: 3px;
        }

        &_btnSeeMore {
            grid-row: 3 / 3;
            place-self: center;

            font-size: var(--fs-heading);
            font-weight: 300;

            padding: 2rem 6rem;

            color: #fff;
            border: none;
            border-radius: .5rem;

            cursor: pointer;

            background-color: rgba(0, 0, 0, 0.80);
            box-shadow: rgba(0, 0, 0, 0.4) 0px 20px 25px -5px, rgba(0, 0, 0, 0.14) 0px 10px 10px -5px;
            cursor: pointer;

            transition: background-color .2s ease;

            &:hover {
                background-color: rgba(0, 0, 0, 0.90);
            }
        }

        &_imgs {
            place-self: center;

            display: flex;
        }
    }
}

.closeArrow {
    z-index: 99999999999;
    cursor: pointer;
    position: absolute;
    top: 5rem;
    right: 5rem;

    height: 8rem;
    width: 8rem;
    fill: #fff;

    transition: fill .2s ease-in;

    &:hover {
        fill: var(--lightBlue);
    }
}

.gallery-fullimg {
    display: flex;
    place-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
    z-index: 1000;
    position: fixed;



    & img {
        z-index: 999;
        max-width: calc(100vw - 10%);
        margin: calc(5% - $navbar-height) 5% 5% 5%;
        max-height: calc(100vh - 10% - $navbar-height);
    }

    &_overlay {
        z-index: 3;
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: hsla(0, 0%, 0%, 0.777);
    }
}

.GalleryPage-container_imgs {
    grid-row: 2 / 2;

    display: flex;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 0 5%;
    justify-content: center;

    & img {
        height: 300px;
        margin: 5px 10px;
        object-fit: contain;
        transition: transform ease-in .1s;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}

@media screen and (max-width: 1560px) {
    .gallery-fullimg {

        & img {
            max-width: calc(100vw - 30%);
        }
    }
}

@media screen and (max-width: 1145px) {
    .gallery-fullimg {

        & img {
            max-width: calc(100vw - 30%);
        }
    }
}

@media screen and (max-width: $bp-medium) {
    .gallery-fullimg {
        & img {
            max-width: calc(100vw - 20%);
        }
    }

    .sliderBtn {
        position: absolute;
        bottom: 12.5rem;
    }

    .nextBtn {
        right: 30%;
    }

    .prevBtn {
        left: 30%;
    }
}

@media screen and (max-width: $bp-small) {
    .GalleryPage-container_imgs {
        & img {
            height: 130px;
        }
    }

    .nextBtn {
        right: 15%;
    }

    .prevBtn {
        left: 15%;
    }
}

@media screen and (max-width: $bp-phone) {
    .GalleryPage-container_imgs {
        & img {
            // height: 80px;
        }
    }
}


.seeMoreBtn {
    grid-column: 1 / -1;
    grid-row: 3 / -1;

    place-self: center;

    padding: 2rem 6rem;

    font-size: var(--fs-text-big);
    color: #fff;
    background-color: hsla(0, 0%, 0%, 0.777);

    transition: background-color .2s ease-out;
    cursor: pointer;

    border: none;
    border-radius: .5rem;

    &:hover {
        background-color: hsla(0, 0%, 0%, 0.57);
    }
}

.none {
    display: none !important;
}

.backArrow {
    grid-column: 1 / -1;
    grid-row: 1 / 1;

    justify-self: start;
    align-self: center;

    margin-left: 4rem;

    text-decoration: none;

    background-color: #000;

    border: none;
    border-radius: 1rem;

    padding: 1rem;

    display: grid;
    place-items: center;

    position: sticky;

    top: 12.5rem;

    @include md() {
        padding: .3rem;

        margin-left: 1.5rem;

        align-self: start;

        top: 10rem;
    }

    &_arrow {
        fill: #fff;
        height: 4rem;
        width: 4rem;
        transform: rotate(-90deg);
    }
}

.sliderBtn {
    fill: #fff;
    background-color: transparent;
    border: solid .2rem #fff;
    border-radius: 50%;

    z-index: 999999;
    cursor: pointer;

    padding: 2rem;

    transition: border .2s ease-in;

    &:hover,
    &:focus {
        border: solid .2rem var(--lightBlue);

        .sliderIcon {
            fill: var(--lightBlue);
        }
    }
}

@media screen and (max-width: $bp-medium) {}



.sliderIcon {
    fill: #fff;

    height: 4rem;
    width: 4rem;

    transition: fill .2s ease-in;

}