@import "./Mixins";

.Nav {
  z-index: 9999999999999 !important;

  position: fixed;

  height: $navbar-height;
  width: 100vw;
  background-color: #000;
  top: 0;

  display: grid;
  grid-template-columns: .5fr 1fr .5fr;
  grid-template-rows: 1fr;

  place-items: center;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px -3px, rgba(0, 0, 0, 0.2) 0px 4px 6px -2px;

  &-icon {
    grid-column: 2 / 2;

    height: 4.5rem;
    display: grid;
    place-items: center;
    cursor: pointer;

    place-self: center;

    &>span {
      width: 50px;
      height: 3px;
      background: #fff;
      transition: all 0.3s ease-in-out;
    }

    &:hover span:nth-child(1) {
      width: 35px;
    }

    &:hover span:nth-child(2) {
      width: 40px;
    }

    &.active span:nth-child(1) {
      transform-origin: center center;
      transform: rotate(-45deg) translate(-9px, 10px);
      width: 55px;
    }

    &.active span:nth-child(2) {
      transform: translateX(10px);
      opacity: 0;
    }

    &.active span:nth-child(3) {
      transform-origin: center center;
      transform: rotate(45deg) translate(-12px, -13px);
      width: 55px;
    }

    &-line1 {
      width: 4.2rem;
      justify-self: end;
    }

    &-line2 {
      margin-top: 1.5rem;
      width: 3.5rem;
      justify-self: end;
    }

    &-line3 {
      margin-top: 1.5rem;
    }
  }

  &-logo {
    grid-column: 1 / 1;
    grid-row: 1 / 1;

    justify-self: start;
    margin-left: 4rem;

    height: 7rem;

    @include md() {
      height: 5.5rem;
    }

    @include sm() {
      margin-left: 0rem;
      justify-self: center;
    }
  }

  &-ulLinks {
    display: flex;
    gap: 5rem;

    grid-column: 2 / 2;
    grid-row: 1 / 1;


    &_item {
      font-family: 'Saira', sans-serif;
      font-weight: 300;
      letter-spacing: .05rem;
      text-decoration: none;
      color: #fff;
      font-size: var(--fs-title);

      transition: color .2s ease-in;

      &:hover {
        color: var(--lightBlue);
      }
    }
  }

  &-ulLinksMobile {
    display: none;
    grid-auto-flow: row;
    gap: 5rem;

    grid-column: 2 / 2;
    grid-row: 1 / 1;


    &_item {
      font-family: 'Saira', sans-serif;
      font-weight: 300;
      letter-spacing: .05rem;
      text-decoration: none;
      color: #fff;
      font-size: var(--fs-text);

      transition: color .2s ease-in;

      &:hover {
        color: var(--lightBlue);
      }
    }
  }

  &-ulSocial {
    display: flex;
    gap: 2rem;

    justify-self: end;
    margin-right: 4rem;

    grid-column: 3 / 3;
    grid-row: 1 / 1;

    @include sm() {
      justify-self: center;
      justify-content: center;
      flex-wrap: wrap;

      margin-right: 0;

      gap: 1.5rem;
    }

    &_link {
      height: 3rem;
      width: 3rem;

      @include sm() {
        height: 1.7rem;
        width: 1.7rem;
      }

      fill: #fff;
      transition: fill .2s ease-in;

      &:hover {
        fill: var(--lightBlue);
      }
    }
  }
}

.dropdown {
  top: 8rem;
  bottom: 0rem;
  position: fixed;
  z-index: 99999999;
  display: none;
  grid-auto-rows: minmax(50px, 110px);
  grid-auto-flow: row;

  place-items: center;

  width: 100vw;
  height: 100%;

  background-color: rgba(0,0,0, .9);

  &_item {
    z-index: 999999999;
    font-family: 'Saira', sans-serif;
    font-weight: 300;
    letter-spacing: .05rem;
    text-decoration: none;
    color: #fff;
    font-size: var(--fs-title);

    transition: color .2s ease-in;

    &:hover {
      color: var(--lightBlue);
    }
  }
}

.activeItem {
  color: var(--lightBlue);
  font-weight: 500;
}

.toggled {
  display: grid;
}