/* ===== Universal Things ===== */
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

:root {

  //* COLORS
  --Gunmetal: #202c39;
  --Charcoal: #283845;
  --Sage: #b8b08d;
  --Champagne: #f2d492;
  --alabaster: #eceeea;
  --Tangerine: #f29559;
  --Tangerine2: #bb6b36;
  --dark-blue-1: #001233;
  --dark-blue-2: #0f2b5f;
  --lightBlue: #2980b9;
  --independece: #33415C;
  --black-coral: #5C677D;
  --roman-silver-1: #7D8597;
  --roman-silver-2: #8990A0;

  //* SHADOWS
  --shadow-light-1: 0 3.5rem 4rem rgba(0, 0, 0, .4);
  --shadow-dark-1: 0 2rem 6rem rgba(0, 0, 0, .4);
  --shadow-dark-2: 0 2rem 4rem rgba(0, 0, 0, .6);
  --shadow-dark-3: 0 1.5rem 7rem rgba(0, 0, 0, .8);
  --shadow-light-4: 0 10px 10px rgba(0, 0, 0, 0.19), 0 10px 1px rgba(0, 24, 69, .5);


  //* FONT-SIZES
  --fs-heading-big: 4rem;
  --fs-heading: 3rem;
  --fs-title: 2.5rem;
  --fs-text-big: 2rem;
  --fs-text: 1.5rem;

  --font-saira: 'Saira', sans-serif;
}


/* MEDIA QUERY SIZES */
$bp-largest: 75em; // 1200px === 75em
$bp-large: 68.75em; // 1100px === 68.75em
$bp-medium: 56.25em; // 900px === 56.25em
$bp-small: 37.5em; // 600px === 37.5em
$bp-smallest: 31.25em; // 500px === 31.25em
$bp-phone: 23.75em; // 380px === 23.75em

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  position: relative;
  // ! This defines what 1rem is.
  font-size: 62.5%;
  box-sizing: border-box;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  background-color: var(--alabaster);
}

.overflow {
  overflow: hidden;
}

::-webkit-scrollbar-track {
  background-color: var(--alabaster);
}

::-webkit-scrollbar {
  width: 10px;
  background-color: var(--alabaster);
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--lightBlue);
}

::selection {
  background-color: var(--lightBlue);
  color: #fff;
}

.default-header {
  grid-column: 1 / -1;
  grid-row: 1 / 1;
  place-self: center;
  font-size: 45px;
  font-family: var(--font-saira);
  text-decoration: underline;
  text-underline-offset: 15px;
  text-decoration-thickness: 3px;
  text-align: center;
  padding-top: 50px;
}

@media screen and(max-width: $bp-medium) {
  .default-header {
    font-size: 30px;
  }
}

@media screen and(max-width: $bp-smallest) {
  .default-header {
    font-size: var(--fs-heading);
  }
}

.toTopBtn {
  transition: all .2s ease-in;

  opacity: 0;

  display: flex;

  justify-content: center;
  align-items: center;

  z-index: 9;
  position: fixed;
  right: 5rem;
  bottom: 5rem;

  border-radius: 50%;

  height: 5rem;
  width: 5rem;

  background-color: #000;

  &-btn {
    height: 3rem;
    width: 3rem;
    fill: #fff;
  }
}

.active {
  cursor: pointer !important;
  opacity: 1 !important;
}

.loadWrap {
  z-index: 99999999;
  height: 100vh;
  width: 100vw;
  position: relative;
  background-color: var(--alabaster);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &_title {
    margin-bottom: 10rem;
    font-size: var(--fs-heading);
  }

  &_loader {
    grid-column: 1 / -1;
    grid-row: 2 / 2;

    place-self: center;
  }
}