@import "./Mixins";
@import './base';

.galleri {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--alabaster);
    width: 100vw;
    margin-top: 1rem;
}

.galleri-container {
    position: relative;
    max-height: 100%;
    width: 100vw;
    z-index: 1;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 200px repeat(2, 1fr);

    @include lg() {
        grid-template-columns: repeat(2, 1fr);
    }

    @include md() {
        grid-template-columns: repeat(1, 1fr);
    }

    & h1 {
        grid-column: 1 / -1;
        grid-row: 1 / 1;

        place-self: center;

        font-size: var(--fs-heading-big);
        font-family: var(--font-saira);
        text-decoration: underline;
        text-underline-offset: 15px;
        text-decoration-thickness: 3px;

    }

    & .grid-item {
        width: 100%;
        height: 40vh;

        display: flex;
        place-content: center;

        &_title {
            color: #fff;
            font-family: var(--font-saira);
            font-weight: 300;

            font-size: var(--fs-heading);

            position: absolute;
            place-self: center;
            z-index: 9999;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(.5);
            transition: filter ease-out .3s;

            &:hover {
                filter: brightness(.2);
            }
        }
    }
}

// .sopp {
//     height: 45% !important;
// }