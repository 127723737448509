@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@import "base/_base.scss";
@import "base/_Nav.scss";
@import "base/_Home.scss";
@import "base/_Gallery.scss";
@import "base/_GalleryPage.scss";
@import "base/_Digitalisering.scss";
@import "base/_Priser.scss";
@import "base/_Mixins.scss";
@import 'base/kontakt.scss';

* { box-sizing: border-box; -moz-box-sizing: border-box;}

.page {
    margin-top: $navbar-height;
    min-height: calc(100vh - $navbar-height);
}
